import { default as _91token_93jUy2Rs4Ji3Meta } from "/vercel/path0/pages/change-email/[token].vue?macro=true";
import { default as _91token_93Jtsat2k7VUMeta } from "/vercel/path0/pages/change-logout-password/[token].vue?macro=true";
import { default as _91token_93skzPMIn8fGMeta } from "/vercel/path0/pages/change-password/[token].vue?macro=true";
import { default as _91token_93sCBLvs2ObjMeta } from "/vercel/path0/pages/change-wallet/[token].vue?macro=true";
import { default as _91uuid_93wv5qXGlDjtMeta } from "/vercel/path0/pages/content-unlock/[uuid].vue?macro=true";
import { default as indexEUVCj2NsV2Meta } from "/vercel/path0/pages/contests/[type]/index.vue?macro=true";
import { default as earn_45morekZokru5SdUMeta } from "/vercel/path0/pages/earn-more.vue?macro=true";
import { default as _91code_93DkVOvwccHYMeta } from "/vercel/path0/pages/email-confirmation/[code].vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as forgot_45password9RntqnVzd3Meta } from "/vercel/path0/pages/forgot-password.vue?macro=true";
import { default as free_45gamesRjdGcgsVD6Meta } from "/vercel/path0/pages/free-games.vue?macro=true";
import { default as freeog4JestZpOMeta } from "/vercel/path0/pages/free.vue?macro=true";
import { default as _91game_93Dq5Mz6LkZlMeta } from "/vercel/path0/pages/games/[provider]/[game].vue?macro=true";
import { default as index5VJlG6PCQRMeta } from "/vercel/path0/pages/games/index.vue?macro=true";
import { default as gamesMVHHaU5D5bMeta } from "/vercel/path0/pages/games.vue?macro=true";
import { default as gdpr0lDzuLgc6ZMeta } from "/vercel/path0/pages/gdpr.vue?macro=true";
import { default as historyccFtdcgOm1Meta } from "/vercel/path0/pages/history.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as _91id_93c2ehOqdJhgMeta } from "/vercel/path0/pages/messages/[id].vue?macro=true";
import { default as indexpt683rA6b7Meta } from "/vercel/path0/pages/messages/index.vue?macro=true";
import { default as _91hash_93NE2xwblozEMeta } from "/vercel/path0/pages/promotion/[hash].vue?macro=true";
import { default as referral_45programc9nKHgV6eaMeta } from "/vercel/path0/pages/referral-program.vue?macro=true";
import { default as registerIikvtlfpbHMeta } from "/vercel/path0/pages/register.vue?macro=true";
import { default as settingsyZZu4Rd1aAMeta } from "/vercel/path0/pages/settings.vue?macro=true";
import { default as vip_45clubN39aGikFlBMeta } from "/vercel/path0/pages/vip-club.vue?macro=true";
export default [
  {
    name: _91token_93jUy2Rs4Ji3Meta?.name ?? "change-email-token",
    path: _91token_93jUy2Rs4Ji3Meta?.path ?? "/change-email/:token()",
    meta: _91token_93jUy2Rs4Ji3Meta || {},
    alias: _91token_93jUy2Rs4Ji3Meta?.alias || [],
    redirect: _91token_93jUy2Rs4Ji3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/change-email/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Jtsat2k7VUMeta?.name ?? "change-logout-password-token",
    path: _91token_93Jtsat2k7VUMeta?.path ?? "/change-logout-password/:token()",
    meta: _91token_93Jtsat2k7VUMeta || {},
    alias: _91token_93Jtsat2k7VUMeta?.alias || [],
    redirect: _91token_93Jtsat2k7VUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/change-logout-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93skzPMIn8fGMeta?.name ?? "change-password-token",
    path: _91token_93skzPMIn8fGMeta?.path ?? "/change-password/:token()",
    meta: _91token_93skzPMIn8fGMeta || {},
    alias: _91token_93skzPMIn8fGMeta?.alias || [],
    redirect: _91token_93skzPMIn8fGMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/change-password/[token].vue").then(m => m.default || m)
  },
  {
    name: _91token_93sCBLvs2ObjMeta?.name ?? "change-wallet-token",
    path: _91token_93sCBLvs2ObjMeta?.path ?? "/change-wallet/:token()",
    meta: _91token_93sCBLvs2ObjMeta || {},
    alias: _91token_93sCBLvs2ObjMeta?.alias || [],
    redirect: _91token_93sCBLvs2ObjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/change-wallet/[token].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93wv5qXGlDjtMeta?.name ?? "content-unlock-uuid",
    path: _91uuid_93wv5qXGlDjtMeta?.path ?? "/content-unlock/:uuid()",
    meta: _91uuid_93wv5qXGlDjtMeta || {},
    alias: _91uuid_93wv5qXGlDjtMeta?.alias || [],
    redirect: _91uuid_93wv5qXGlDjtMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/content-unlock/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexEUVCj2NsV2Meta?.name ?? "contests-type",
    path: indexEUVCj2NsV2Meta?.path ?? "/contests/:type()",
    meta: indexEUVCj2NsV2Meta || {},
    alias: indexEUVCj2NsV2Meta?.alias || [],
    redirect: indexEUVCj2NsV2Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contests/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: earn_45morekZokru5SdUMeta?.name ?? "earn-more",
    path: earn_45morekZokru5SdUMeta?.path ?? "/earn-more",
    meta: earn_45morekZokru5SdUMeta || {},
    alias: earn_45morekZokru5SdUMeta?.alias || [],
    redirect: earn_45morekZokru5SdUMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/earn-more.vue").then(m => m.default || m)
  },
  {
    name: _91code_93DkVOvwccHYMeta?.name ?? "email-confirmation-code",
    path: _91code_93DkVOvwccHYMeta?.path ?? "/email-confirmation/:code()",
    meta: _91code_93DkVOvwccHYMeta || {},
    alias: _91code_93DkVOvwccHYMeta?.alias || [],
    redirect: _91code_93DkVOvwccHYMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/email-confirmation/[code].vue").then(m => m.default || m)
  },
  {
    name: faqM4LN7jxnMuMeta?.name ?? "faq",
    path: faqM4LN7jxnMuMeta?.path ?? "/faq",
    meta: faqM4LN7jxnMuMeta || {},
    alias: faqM4LN7jxnMuMeta?.alias || [],
    redirect: faqM4LN7jxnMuMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password9RntqnVzd3Meta?.name ?? "forgot-password",
    path: forgot_45password9RntqnVzd3Meta?.path ?? "/forgot-password",
    meta: forgot_45password9RntqnVzd3Meta || {},
    alias: forgot_45password9RntqnVzd3Meta?.alias || [],
    redirect: forgot_45password9RntqnVzd3Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: free_45gamesRjdGcgsVD6Meta?.name ?? "free-games",
    path: free_45gamesRjdGcgsVD6Meta?.path ?? "/free-games",
    meta: free_45gamesRjdGcgsVD6Meta || {},
    alias: free_45gamesRjdGcgsVD6Meta?.alias || [],
    redirect: free_45gamesRjdGcgsVD6Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/free-games.vue").then(m => m.default || m)
  },
  {
    name: freeog4JestZpOMeta?.name ?? "free",
    path: freeog4JestZpOMeta?.path ?? "/free",
    meta: freeog4JestZpOMeta || {},
    alias: freeog4JestZpOMeta?.alias || [],
    redirect: freeog4JestZpOMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/free.vue").then(m => m.default || m)
  },
  {
    path: gamesMVHHaU5D5bMeta?.path ?? "/games",
    children: [
  {
    name: _91game_93Dq5Mz6LkZlMeta?.name ?? "games-provider-game",
    path: _91game_93Dq5Mz6LkZlMeta?.path ?? ":provider()/:game()",
    meta: _91game_93Dq5Mz6LkZlMeta || {},
    alias: _91game_93Dq5Mz6LkZlMeta?.alias || [],
    redirect: _91game_93Dq5Mz6LkZlMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/games/[provider]/[game].vue").then(m => m.default || m)
  },
  {
    name: index5VJlG6PCQRMeta?.name ?? "games",
    path: index5VJlG6PCQRMeta?.path ?? "",
    meta: index5VJlG6PCQRMeta || {},
    alias: index5VJlG6PCQRMeta?.alias || [],
    redirect: index5VJlG6PCQRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/games/index.vue").then(m => m.default || m)
  }
],
    name: gamesMVHHaU5D5bMeta?.name ?? undefined,
    meta: gamesMVHHaU5D5bMeta || {},
    alias: gamesMVHHaU5D5bMeta?.alias || [],
    redirect: gamesMVHHaU5D5bMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/games.vue").then(m => m.default || m)
  },
  {
    name: gdpr0lDzuLgc6ZMeta?.name ?? "gdpr",
    path: gdpr0lDzuLgc6ZMeta?.path ?? "/gdpr",
    meta: gdpr0lDzuLgc6ZMeta || {},
    alias: gdpr0lDzuLgc6ZMeta?.alias || [],
    redirect: gdpr0lDzuLgc6ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/gdpr.vue").then(m => m.default || m)
  },
  {
    name: historyccFtdcgOm1Meta?.name ?? "history",
    path: historyccFtdcgOm1Meta?.path ?? "/history",
    meta: historyccFtdcgOm1Meta || {},
    alias: historyccFtdcgOm1Meta?.alias || [],
    redirect: historyccFtdcgOm1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/history.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenanceLjk4bqrWMWMeta?.name ?? "maintenance",
    path: maintenanceLjk4bqrWMWMeta?.path ?? "/maintenance",
    meta: maintenanceLjk4bqrWMWMeta || {},
    alias: maintenanceLjk4bqrWMWMeta?.alias || [],
    redirect: maintenanceLjk4bqrWMWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c2ehOqdJhgMeta?.name ?? "messages-id",
    path: _91id_93c2ehOqdJhgMeta?.path ?? "/messages/:id()",
    meta: _91id_93c2ehOqdJhgMeta || {},
    alias: _91id_93c2ehOqdJhgMeta?.alias || [],
    redirect: _91id_93c2ehOqdJhgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpt683rA6b7Meta?.name ?? "messages",
    path: indexpt683rA6b7Meta?.path ?? "/messages",
    meta: indexpt683rA6b7Meta || {},
    alias: indexpt683rA6b7Meta?.alias || [],
    redirect: indexpt683rA6b7Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: _91hash_93NE2xwblozEMeta?.name ?? "promotion-hash",
    path: _91hash_93NE2xwblozEMeta?.path ?? "/promotion/:hash()",
    meta: _91hash_93NE2xwblozEMeta || {},
    alias: _91hash_93NE2xwblozEMeta?.alias || [],
    redirect: _91hash_93NE2xwblozEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/promotion/[hash].vue").then(m => m.default || m)
  },
  {
    name: referral_45programc9nKHgV6eaMeta?.name ?? "referral-program",
    path: referral_45programc9nKHgV6eaMeta?.path ?? "/referral-program",
    meta: referral_45programc9nKHgV6eaMeta || {},
    alias: referral_45programc9nKHgV6eaMeta?.alias || [],
    redirect: referral_45programc9nKHgV6eaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/referral-program.vue").then(m => m.default || m)
  },
  {
    name: registerIikvtlfpbHMeta?.name ?? "register",
    path: registerIikvtlfpbHMeta?.path ?? "/register",
    meta: registerIikvtlfpbHMeta || {},
    alias: registerIikvtlfpbHMeta?.alias || [],
    redirect: registerIikvtlfpbHMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsyZZu4Rd1aAMeta?.name ?? "settings",
    path: settingsyZZu4Rd1aAMeta?.path ?? "/settings",
    meta: settingsyZZu4Rd1aAMeta || {},
    alias: settingsyZZu4Rd1aAMeta?.alias || [],
    redirect: settingsyZZu4Rd1aAMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: vip_45clubN39aGikFlBMeta?.name ?? "vip-club",
    path: vip_45clubN39aGikFlBMeta?.path ?? "/vip-club",
    meta: vip_45clubN39aGikFlBMeta || {},
    alias: vip_45clubN39aGikFlBMeta?.alias || [],
    redirect: vip_45clubN39aGikFlBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/vip-club.vue").then(m => m.default || m)
  }
]